<template>
  <p-dialog :value="value" min-width="1200px" max-width="1200px" min-height="85vh">
    <template #toolbar>
      <v-toolbar-title>Select Content</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn flat icon @click="$emit('input', false)">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
    <v-layout row wrap>
      <v-toolbar class="transparent elevation-0">
        <v-alert v-if="filterType" :value="true" color="info" icon="info" outline class="filter-alert">
          <div class="filter-alert-text">
            Showing filtered content type: <b>{{ filterType }}</b>
          </div>
        </v-alert>
        <v-spacer></v-spacer>
        <search-bar
          v-if="library"
          :library="library._id"
          @select="c => selectContent(c.data)"
          @search="searchContent"
          :filter="filterType" />
        <template slot="extension">
          <breadcrumb :path="res.path" @change="openFolder">
            <v-btn
              small
              @click="
                library = ''
                selected = false
                res = { docs: [], path: [] }
              "
              flat
              :disabled="!library"
              >PLANT
            </v-btn>
            <v-icon v-if="library" class="mr-1" small>chevron_right</v-icon>
            <v-btn v-if="library" small @click="openFolder()" flat :disabled="res.path && res.path.length == 0">
              <v-icon small left>mdi-library-shelves</v-icon>
              {{ library.name }}
            </v-btn>
            <v-icon v-if="res.path && res.path.length > 0" class="mr-1" small>chevron_right</v-icon>
          </breadcrumb>
          <v-spacer></v-spacer>
          <content-view-toggle v-model="view" v-show="library" />
        </template>
      </v-toolbar>
      <v-card-text v-if="library">
        <content-list
          v-if="view == 'list'"
          layout="selectContentDailog"
          :items="res.docs"
          :showCheck="isMultipleSelection"
          defaultSort="name"
          @input="selectContent"
          @folder="f => openFolder(f._id)"
          @change="changeSelected"
          @toggleAll="toggleAllItems" />
        <content-grid
          v-else
          :items="res.docs"
          :view="view"
          :library="library._id"
          @folderChanged="openFolder"
          :selected.sync="selected" />
      </v-card-text>
      <v-card-text v-else>
        <content-grid-libraries :libraries="libraries" @select="selectLibrary" />
      </v-card-text>
    </v-layout>
    <template #actions>
      <v-spacer></v-spacer>
      <v-btn outline color="danger" @click="$emit('input', false)">close</v-btn>
      <v-btn outline color="primary" @click="emitSelected" :disabled="!canEmmitSelected">Select</v-btn>
    </template>
  </p-dialog>
</template>
<script>
import { PDialog, ContentList } from "plant-common"
import { libraryApi } from "@/api/index.js"
import ContentViewToggle from "./ContentViewToggle.vue"
import ContentGrid from "./ContentGrid.vue"
import ContentGridLibraries from "./ContentGridLibraries.vue"
import Breadcrumb from "./Breadcrumb.vue"
import SearchBar from "./SearchBar.vue"

export default {
  name: "ContentSelectorDialog",
  components: {
    ContentViewToggle,
    Breadcrumb,
    ContentGrid,
    ContentGridLibraries,
    PDialog,
    SearchBar,
    ContentList
  },
  props: ["filters"],
  data() {
    return {
      res: { docs: [], path: [] },
      library: undefined,
      libraries: [],
      view: "icons",
      selected: false,
      value: true
    }
  },
  computed: {
    filterType() {
      return this.filters && this.filters.type ? this.filters.type : false
    },
    isMultipleSelection() {
      return this.filters && this.filters.multipleSelection ? this.filters.multipleSelection : false
    },
    multipleSelectedItems() {
      return this.res.docs.filter(el => el.selected)
    },
    canEmmitSelected: function () {
      if (this.view === "list" && this.multipleSelectedItems.length) return true
      return (this.view === "icons" || this.view === "preview") && this.selected
    }
  },

  watch: {
    view(value, oldValue) {
      if (oldValue === "list" && this.multipleSelectedItems.length) this.toggleAllItems(false)
    }
  },

  async created() {
    const { data } = await libraryApi.getLibraryList()
    this.libraries = data.filter(lib => lib.active === true)
  },

  methods: {
    async openFolder(folder_id) {
      const LIMIT = 2000
      this.selected = false

      if (folder_id) {
        var { data } = await libraryApi.getFolderContents(this.library._id, folder_id, undefined, LIMIT)
      } else {
        var { data } = await libraryApi.getRootFolderContents(this.library._id, undefined, LIMIT)
      }
      const filterData = {
        docs: this.filterType
          ? data.docs.filter(content => {
              if (Array.isArray(this.filterType))
                return !content.content_revision_type || this.filterType.includes(content.content_revision_type)
              return (
                !content.content_revision_type ||
                (content.content_revision_type && content.content_revision_type === this.filterType)
              )
            })
          : data.docs,
        path: data.path,
        paginator: data.paginator
      }
      this.res = filterData
    },
    async selectLibrary(library) {
      this.library = library
      await this.openFolder()
    },

    async prepareElement(element) {
      const { header } = element
      if (!header) return null

      const elementPrepared = (
        await libraryApi.getContentByRevision(
          this.library._id,
          header.metadata.content_id,
          header.major_revision,
          header.minor_revision
        )
      ).data

      Object.assign(elementPrepared, {
        $origin: `plant://${this.library._id}/content/${header.metadata.content_id}/${header.major_revision}/${header.minor_revision}`
      })

      return elementPrepared
    },

    async emitSelected() {
      if (!this.multipleSelectedItems.length) {
        const element = await this.prepareElement(this.selected)
        this.$emit("input", element)
      } else {
        const itemsToEmit = []
        for (let el of this.multipleSelectedItems) {
          const element = await this.prepareElement(el)
          if (element) itemsToEmit.push(element)
        }

        this.$emit("input", itemsToEmit)
      }
    },

    selectContent(content) {
      this.selected = content
      this.emitSelected()
    },
    searchContent(res) {
      this.res = {
        docs: res.docs,
        path: []
      }
    },
    changeSelected({ item, $event }) {
      const el = this.res.docs.find(el => el._id === item._id)
      el.selected = $event
    },
    toggleAllItems(flag) {
      this.res.docs.forEach(el => (el.selected = flag))
    }
  }
}
</script>

<style scoped>
.filter-alert {
  max-width: 800px;
  max-height: 60px;
}
.filter-alert-text {
  height: 50px;
  overflow: auto;
}
</style>
