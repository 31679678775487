<template>
	<basic-block :value="value" :mode="mode">
		<template v-slot:main>
			<v-card tile class="lp-block-objectives">
				<v-window
					v-if="objectives && objectives.length > 0"
					:style="{
						fontFamily: value.data.fontFamily || 'inherit'
					}"
					v-model="objectiveItemIndex"
				>
					<v-window-item v-for="(obj, indexObj) in objectives" :key="indexObj">
						<v-card color="transparent" height="100%">
							<v-layout class="lp-block-objectives-content" tag="v-card-text">
								<h2 v-if="value.data.code !== 'hide'" class="lp-block-objectives-title mb-4">
									{{ getTitleCode(obj, indexObj) }}
								</h2>
								<div class="lp-block-objectives-description">
									<p-rich-text
										:value="obj.body.contents.text"
										:canEdit="false"
										min-height="fit-content"
										fontSize="45"
										padding="0px"
									/>
								</div>
							</v-layout>
						</v-card>
					</v-window-item>
				</v-window>

				<v-card-actions class="justify-center mt-4" v-if="objectives && objectives.length > 0">
					<v-btn flat @click="prev" class="arrow prev" :style="arrowsStyle" :class="{ disabled: isLeftArrowDisabled }">
						<v-icon class="arrow-icon">mdi-chevron-left</v-icon>
					</v-btn>
					<v-item-group v-model="objectiveItemIndex" class="text-xs-center" mandatory>
						<v-item v-for="(objective, index) in objectives" :key="index">
							<v-btn
								slot-scope="{ active, toggle }"
								:input-value="active"
								icon
								@click="toggle"
								class="bullet"
								:style="paginatorStyle(index)"
								:id="`objective-bullet-${index}`"
							>
								<v-icon>mdi-record</v-icon>
							</v-btn>
						</v-item>
					</v-item-group>
					<v-btn flat @click="next" class="arrow next" :style="arrowsStyle" :class="{ disabled: isRightArrowDisabled }">
						<v-icon class="arrow-icon">mdi-chevron-right</v-icon>
					</v-btn>
				</v-card-actions>
				<div v-else class="text-center lp-block-objectives-empty">
					<img :src="require('../../components/slides/objectives/select-objective.svg')" />
					<h2>No Learning Objectives found in this presentation.</h2>
					<h3>When you add Learning objectives to a section, they will appear here.</h3>
				</div>
			</v-card>
		</template>
	</basic-block>
</template>

<script>
import BasicBlock from '../_components/containers/BasicBlock.vue';
import { PRichText } from 'plant-common';
import { cloneDeep } from '../../utils/utils.js';
import { getTitle, getCustomId } from '../../../../utils/dbTool';

const LEVEL_COG_TERMINAL = 'Cognitive Terminal';
const LEVEL_PERF_TERMINAL = 'Performance Terminal';

export default {
	name: 'BlockObjectives',
	inject: ['blockController', 'lessonPlanController'],
	components: { BasicBlock, PRichText },
	props: ['value', 'mode'],
	data() {
		return {
			objectiveItemIndex: 0,
			objectives: [],
			terminalObjectivesLength: 0
		};
	},
	computed: {
		objectiveFields() {
			const fields = this.lessonPlanController.fields
				? this.lessonPlanController.fields.find(field => field.hasOwnProperty('objectives'))
				: null;
			return fields ? fields.objectives : null;
		},
		arrowsStyle() {
			return {
				'--arrows-color-background': this.value.data.style.arrows.normal.backgroundColor,
				'--arrows-color-arrows': this.value.data.style.arrows.normal.arrowColor,
				'--arrows-color-background-hover': this.value.data.style.arrows.hover.backgroundColor,
				'--arrows-color-arrows-hover': this.value.data.style.arrows.hover.arrowColor
			};
		},
		generalObjective() {
			return this.lessonPlanController.generalObjective;
		},
		isLoopNavigation() {
			return this.value.data.navigation.loop;
		},
		isLeftArrowDisabled() {
			return !this.isLoopNavigation && this.objectiveItemIndex === 0;
		},
		isRightArrowDisabled() {
			return !this.isLoopNavigation && this.objectiveItemIndex === this.objectives.length - 1;
		}
	},
	watch: {
		value: {
			deep: true,
			handler() {
				this.setObjectives();
			}
		},
		'value.data.style': {
			deep: true,
			handler() {
				if (document.getElementById('objective-bullet-0')) {
					this.objectives.forEach((obj, index) => {
						let bullet = document.getElementById(`objective-bullet-${index}`);
						if (this.cardItem === index) {
							bullet.style.color = this.value.data.style.paginator.active.backgroundColor;
						} else if (this.isObjectiveVisited(index) && this.mode !== 'edit') {
							bullet.style.color = this.value.data.style.paginator.visited.backgroundColor;
						} else {
							bullet.style.color = this.value.data.style.paginator.normal.backgroundColor;
						}
					});
				}
			}
		},
		objectiveItemIndex: {
			immediate: false,
			handler() {
				if (!this.isObjectiveVisited(this.objectiveItemIndex) && this.mode !== 'edit') {
					this.$set(this.value.data.objectives[this.objectiveItemIndex], 'visited', true);
				}
			}
		}
	},
	mounted() {
		this.setObjectives();
		this.setVisitDefaultValues();
		if (this.value.data.objectives && this.value.data.objectives[0] && this.mode !== 'edit') {
			this.$set(this.value.data.objectives[0], 'visited', true);
		}
	},
	methods: {
		next() {
			this.objectiveItemIndex =
				this.objectiveItemIndex + 1 === this.objectives.length ? 0 : this.objectiveItemIndex + 1;
		},
		prev() {
			this.objectiveItemIndex =
				this.objectiveItemIndex - 1 < 0 ? this.objectives.length - 1 : this.objectiveItemIndex - 1;
		},
		setObjectives() {
			const terminalObjectives = this.objectiveFields.filter(objective => this.isObjectiveTerminal(objective));
			this.terminalObjectivesLength = terminalObjectives.length;
			this.objectives = cloneDeep(this.objectiveFields.filter(objective => !this.isObjectiveTerminal(objective)));
			if (this.value.data.order === 'alphabetical') {
				this.objectives.sort((a, b) => a.header.metadata.custom_id.localeCompare(b.header.metadata.custom_id));
				terminalObjectives.sort((a, b) => a.header.metadata.custom_id.localeCompare(b.header.metadata.custom_id));
			}
			if (this.generalObjective) {
				this.objectives.unshift(this.generalObjective);
			}
			// Set Terminal objectives at first position
			this.objectives = terminalObjectives.concat(this.objectives);
		},
		isObjectiveTerminal(obj) {
			const level = obj && obj.body && obj.body.contents && obj.body.contents.level ? obj.body.contents.level : '';
			return level === LEVEL_COG_TERMINAL || level === LEVEL_PERF_TERMINAL;
		},
		isObjectiveVisited(objectiveItemIndex) {
			return this.value.data.objectives[objectiveItemIndex].visited;
		},
		paginatorStyle(index) {
			const backgroundNormal = this.value.data.style.paginator.normal.backgroundColor;
			const backgroundActive = this.value.data.style.paginator.active.backgroundColor;
			const backgroundVisited = this.value.data.style.paginator.visited.backgroundColor;
			return {
				'--pagination-color-background':
					index === this.objectiveItemIndex
						? backgroundActive
						: this.isObjectiveVisited(index) && this.objectiveItemIndex !== index
							? backgroundVisited
							: backgroundNormal,
				'--pagination-color-background-hover': this.value.data.style.paginator.hover.backgroundColor
			};
		},
		getTitleCode(obj, indexObj) {
			let titleCode = '';
			if (this.isObjectiveTerminal(obj)) {
				titleCode = 'Terminal Objective';
			} else {
				switch (this.value.data.code) {
					case 'custom_id':
						titleCode = getCustomId(obj);
						break;
					case 'title':
						titleCode = getTitle(obj);
						break;
					case 'objective_order':
						titleCode = `Objective ${indexObj + this.value.data.starterNumber - this.terminalObjectivesLength}`;
						break;
					default:
						titleCode = getCustomId(obj);
						break;
				}
			}
			return titleCode;
		},
		setVisitDefaultValues() {
			const defaultObjectives = [];
			this.objectives.forEach(objective => {
				defaultObjectives.push({
					_id: objective._id
				});
			});
			this.value.data.objectives = defaultObjectives;
		}
	},
	created() {
		// Ensure backwards compatibility with block's previous version
		if (!this.value.data.hasOwnProperty('style')) {
			this.$set(this.value.data, 'style', {
				transition: {
					type: 'none'
				},
				arrows: {
					normal: {
						backgroundColor: '#ffffffff',
						arrowColor: '#000000e6'
					},
					hover: {
						backgroundColor: '#cccccc',
						arrowColor: '#000000e6'
					}
				},
				paginator: {
					normal: {
						backgroundColor: '#cccccc'
					},
					hover: {
						backgroundColor: '#000000'
					},
					active: {
						backgroundColor: '#2b52ff'
					},
					visited: {
						backgroundColor: '#cc0000'
					}
				}
			});
		}
		if (!this.value.data.hasOwnProperty('code')) {
			this.$set(this.value.data, 'code', 'custom_id');
		}
		if (!this.value.data.hasOwnProperty('objectives')) {
			this.$set(this.value.data, 'objectives', []);
		}
		// Set default navigation property to old objectives block (if it is not set)
		if (!this.value.data.hasOwnProperty('navigation')) this.$set(this.value.data, 'navigation', { loop: true });

		if (!this.value.data.hasOwnProperty('starterNumber')) this.$set(this.value.data, 'starterNumber', 1);
	}
};
</script>

<style>
.lp-block-objectives {
	height: 90%;
	box-shadow: none !important;
}
.lp-block-objectives .v-window,
.lp-block-objectives .v-window__container,
.lp-block-objectives .v-window-item,
.lp-block-objectives .v-card {
	height: 100%;
}
.lp-block-objectives-content.v-card__text {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 80%;
	margin: 0 auto;
}
.lp-block-objectives-description {
	overflow: auto;
	max-height: 70%;
	padding: 0px 50px;
	width: 100%;
}
.lp-block-objectives-description ol,
.lp-block-objectives-description ul {
	padding-left: 72px;
}
.lp-block-objectives-empty {
	padding: 15px;
}
.lp-block-objectives-empty img {
	width: 40%;
	padding: 25px;
	margin-top: 40px;
}
.lp-block-objectives-empty h2 {
	font-size: 60px;
}
.lp-block-objectives-empty h3 {
	font-size: 50px;
	font-weight: 400;
}
.lp-block-objectives-title {
	font-size: 56px !important;
}

.arrow {
	background: var(--arrows-color-background);
	color: var(--arrows-color-arrows) !important;
	padding: 0 !important;
	min-height: 50px !important;
	min-width: 50px !important;
	border-radius: 50% !important;
	position: absolute !important;
	top: 50% !important;
	font-size: 50px !important;
}
.arrow:hover {
	background: var(--arrows-color-background-hover);
	color: var(--arrows-color-arrows-hover) !important;
}
.arrow.prev {
	left: 0;
}
.arrow.next {
	right: 0;
}
.arrow-icon {
	font-size: 50px !important;
}

.bullet {
	color: var(--pagination-color-background) !important;
}
.bullet:hover {
	color: var(--pagination-color-background-hover) !important;
}
</style>
