<template>
  <div>
    <v-toolbar flat class="transparent">
      <v-toolbar-title>Learning Objectives</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        icon
        @click="selectContent"
        v-if="lessonPlanController.mode === 'edit' && lessonPlanController.isSelectedSection">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-toolbar>
    <v-list>
      <div v-if="objectives.length === 0 && lessonPlanController.isSelectedSection" class="text-center">
        <img :src="require('./select-objective.svg')" alt="Import objective" style="width: 100%; padding: 25px" />
        <h2 class="subheading text--lighten-2 mb-3 mt-3">
          Associate this section of the presentation with a Learning Objective. This will ensure traceability with the
          Systematic Approach to Training process.
        </h2>
        <v-btn @click="selectContent" :disabled="lessonPlanController.mode !== 'edit'" outline color="primary">
          <v-icon left>mdi-folder-open-outline</v-icon>
          Select Objective
        </v-btn>
      </div>
      <v-list-tile avatar v-for="(objective, index) in objectives" :key="index">
        <v-list-tile-action v-if="objective">
          <content-type-icon :type="getType(objective)"></content-type-icon>
        </v-list-tile-action>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-list-tile-content v-on="on">
              <v-list-tile-sub-title>{{ getCustomId(objective) }}</v-list-tile-sub-title>
              <v-list-tile-title>
                <p class="lp-objectives-panel-title">
                  <p-rich-text
                    :value="objective.body.contents.text"
                    :canEdit="false"
                    min-height="fit-content"
                    padding="0px"
                    fontSize="16" />
                </p>
              </v-list-tile-title>
            </v-list-tile-content>
          </template>
          <span>
            <p-rich-text
              :value="objective.body.contents.text"
              :canEdit="false"
              min-height="fit-content"
              padding="0px" />
          </span>
        </v-tooltip>

        <v-list-tile-action>
          <v-btn flat icon>
            <v-icon @click="quickPreview(objective)">mdi-open-in-new</v-icon>
          </v-btn>
        </v-list-tile-action>
        <v-list-tile-action v-if="lessonPlanController.mode === 'edit' && lessonPlanController.isSelectedSection">
          <v-btn flat icon>
            <v-icon color="red" @click="removeObjective(index)">mdi-delete-outline</v-icon>
          </v-btn>
        </v-list-tile-action>
      </v-list-tile>
    </v-list>
  </div>
</template>

<script>
import ObjectivePreview from "./ObjectivePreview.vue"
import PServiceContent from "plant-common/src/services/PServiceContent"
import componentModal from "../../../../../utils/componentModal.js"
import { getCustomId, getTitle, getContentId, getType } from "plant-common/src/utils/index"
import ContentTypeIcon from "plant-common/src/components/ContentTypeIcon/index.vue"
import { STORE_NOTIFICATIONS_NAME, ACTION_NOTIFICATIONS_WARNING } from "../../../../../store_constants/notifications"
import { mapActions } from "vuex"
import { PRichText } from "plant-common"

export default {
  inject: ["lessonPlanController"],
  components: { ContentTypeIcon, PRichText },
  computed: {
    objectives() {
      return this.lessonPlanController.selectedSlideObjectives
    }
  },
  methods: {
    ...mapActions(STORE_NOTIFICATIONS_NAME, {
      warning: ACTION_NOTIFICATIONS_WARNING
    }),
    getCustomId: getCustomId,
    getTitle: getTitle,
    getContentId: getContentId,
    getType: getType,
    async selectContent() {
      var newContentRef = await PServiceContent.selectContent({ type: "objective" })
      if (!newContentRef) return

      if (!this.isObjectiveInSlide(newContentRef)) {
        this.objectives.push(newContentRef)
      } else {
        this.warning({
          message: "Objective already added to this section"
        })
      }
    },
    isObjectiveInSlide(newContentRef) {
      return this.objectives.find(o => o.header.metadata.content_id === newContentRef.header.metadata.content_id)
    },
    removeObjective(index) {
      this.objectives.splice(index, 1)
    },
    async quickPreview(content) {
      await componentModal(ObjectivePreview, { content })
    }
  }
}
</script>

<style>
.lp-objectives-panel-title p {
  width: 190px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
